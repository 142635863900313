body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Futura Round Demi';
	src: local('Futura Round Demi'), url('./fonts/FuturaRound-Demi.ttf') format('truetype');
}
@font-face {
	font-family: 'Futura Round Cond Light';
	src: local('Futura Round Cond Light'),
		url('./fonts/FuturaRound-CondLight.ttf') format('truetype');
}
